<template>
  <div class="Settings">
    <v-container class="d-flex justify-center mt-4 pa-4 rscope">
      <v-btn @click="toggleGroup = 0" x-large :color="isActive(0)">
        {{ $t("store.shops") }}
      </v-btn>
      <v-btn @click="toggleGroup = 1" x-large :color="isActive(1)">
        {{ $t("store.displayItems") }}
      </v-btn>
      <v-btn
        v-if="toggleGroup == 1"
        @click="handleSchedule()"
        x-large
        class="absRight"
      >
        <v-icon class="pr-2">mdi-camera-timer</v-icon>
        {{ $t("store.scheduling") }}
      </v-btn>
    </v-container>
    <Shops v-if="isGroup1"></Shops>
    <DisplayItems v-if="isGroup2"></DisplayItems>
  </div>
</template>

<script>
// import Pager from "../components/Pager.vue";
import eventBus from "../eventBus.js";
import Shops from "../components/Shops.vue";
import DisplayItems from "../components/DisplayItems.vue";
import messageBus from "../messageBus.js";

export default {
  name: "settingsView",
  components: { Shops, DisplayItems },
  data() {
    return {
      toggleGroup: 1,
    };
  },
  methods: {
    isActive(toggle) {
      return this.toggleGroup === toggle ? "secondary" : "";
    },
    handleSchedule() {
      eventBus.$emit("SCHEDULE_NOW");
    },
  },
  computed: {
    isGroup1() {
      return this.toggleGroup === 0;
    },
    isGroup2() {
      return this.toggleGroup === 1;
    },
  },
};
</script>

<style scoped>
.rscope {
  position: relative;
  margin: 0 auto;
}
.absRight {
  position: absolute;
  right: 0;
}
</style>
