<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>{{ $t("store.displayItems") }}</v-card-title>
      <v-card-text>
        <v-form class="NewsItem" v-model="isValid">
          <v-text-field
            :label="$t('form.name')"
            outlined
            v-model="postData.name"
            required
            :rules="required"
          ></v-text-field>
          <v-text-field
            :label="$t('store.capacity')"
            outlined
            v-model="postData.capacity"
            required
            :rules="required"
            type="number"
          ></v-text-field>
          <v-text-field
            :label="$t('store.size')"
            outlined
            v-model="postData.size"
            required
            :rules="required"
          ></v-text-field>
          <v-text-field
            :label="$t('store.value')"
            outlined
            v-model="postData.value"
            required
            type="number"
            :rules="required"
          ></v-text-field>
          <v-file-input
            prepend-icon="mdi-camera"
            v-model="image"
            label="Kép"
            accept="image/png, image/jpeg, image/bmp"
          ></v-file-input>
          <file-preview
            v-if="edit"
            :nodelete="true"
            :items="[this.editItem.sampleImage]"
          ></file-preview>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          x-large
          color="secondary"
          :disabled="!isSubmit"
          @click="submit()"
        >
          {{ $t("form.send") }}
        </v-btn>
        <v-btn x-large @click="cancel()">
          {{ $t("form.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import FilePreview from "./FilePreview.vue";
import { API_BASE } from "../util/constants";
import { getMultipartHeader, toQueryString } from "../util/functions.js";
export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    editItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    FilePreview,
  },
  data() {
    return {
      showDialog: true,
      isLocked: false,
      isValid: false,
      postData: {
        capacity: 0,
        name: "",
        size: "",
        promotionalValue: 0,
        value: 0,
      },
      required: [(v) => !!v || this.$t("form.requiredGeneral")],
      image: null,
    };
  },
  mounted() {
    // console.log(this.$jwt.decode());
    this.postData.capacity = this.editItem.capacity;
    this.postData.name = this.editItem.name;
    this.postData.size = this.editItem.size;
    this.postData.value = this.editItem.value;
  },
  computed: {
    isSubmit() {
      if (!this.edit) {
        return this.isValid && this.image !== null;
      } else {
        return this.isValid;
      }
    },
  },
  methods: {
    cancel() {
      eventBus.$emit("CANCEL_DISPLAY_ITEM");
    },
    submit() {
      if (this.edit) {
        this.patch();
        return;
      }
      let formdata = new FormData();
      formdata.append("image", this.image, this.image.name);
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      const query_string = toQueryString(this.postData);
      fetch(
        `${API_BASE}admin/fridge-display-types?${query_string}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          eventBus.$emit("LOAD_OFF");
          eventBus.$emit("SAVE_DISPLAY_ITEM");
        })
        .catch((error) => console.log("error", error));
    },
    patch() {
      let formdata = new FormData();
      if (this.image !== null) {
        formdata.append("image", this.image, this.image.name);
      }
      formdata.append("capacity", this.postData.capacity);
      formdata.append("name", this.postData.name);
      formdata.append("value", this.postData.value);
      formdata.append("size", this.postData.size);

      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      const query_string = toQueryString(this.postData);
      fetch(
        `${API_BASE}admin/fridge-display-types/${this.editItem.id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          eventBus.$emit("LOAD_OFF");
          eventBus.$emit("SAVE_DISPLAY_ITEM");
        })
        .catch((error) => console.log("error", error));
    },
    reset() {},
  },
};
</script>

<style scoped lang="scss"></style>
