<template>
  <div class="DisplayItems">
    <FridgeList></FridgeList>
  </div>
</template>

<script>
import FridgeList from "../components/FridgeList";
export default {
  name: "DisplayItems",
  components: {
    FridgeList,
  },
};
</script>
