<template>
  <div class="Clients content">
    <v-container v-if="!isEdit">
      <v-card>
        <v-card-text>
          <v-data-table
            v-model="activeItems"
            :single-select="false"
            item-key="name"
            hide-default-footer
            :headers="headers"
            :items="data"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-xs-right">
                  <v-simple-checkbox
                    v-model="row.item.selected"
                    :ripple="false"
                    @click="updateSelected()"
                  ></v-simple-checkbox>
                </td>
                <td class="text-xs-right">{{ row.item.name }}</td>
                <td class="text-xs-right">
                  <v-img
                    class="ma-2"
                    max-width="80"
                    max-height="80"
                    @click="showImage(row.item)"
                    :src="getImageUrl(row.item)"
                  ></v-img>
                </td>
                <td class="text-xs-right">{{ row.item.size }}</td>
                <td class="text-xs-right">{{ row.item.capacity }}</td>
                <td class="text-xs-right">{{ row.item.value }}</td>
                <td class="text-xs-right">
                  {{ row.item.promotionalValue }} <br />
                  <small
                    style="position: absolute"
                    class="span"
                    v-if="row.item.promotionalValue > 0"
                  >
                    {{ formatDate(row.item.promotionStartDate) }} -
                    {{ formatDate(row.item.promotionEndDate) }}
                  </small>
                </td>
                <td class="text-xs-right">
                  <v-btn icon @click="deleteItem(row.item)" elevation="1">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="editItem(row.item)" elevation="1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <p>{{ $t("app.noData") }}</p>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="isSchedule" max-width="600">
      <Schedule v-if="isSchedule" :items="activeItems"></Schedule>
    </v-dialog>
    <v-dialog v-model="isNewItem" max-width="600">
      <AddDisplayItem :edit="false"></AddDisplayItem>
    </v-dialog>
    <v-dialog v-model="isEdit" max-width="600">
      <AddDisplayItem :edit="true" :edit-item="activeItem"></AddDisplayItem>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="isImageViewer">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.image") }}
        </v-card-title>

        <v-card-text>
          <v-img @click="isImageViewer = false" :src="previewLink"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <div class="f">
        <div class="f center">
          <v-btn
            fab
            color="black"
            class="mr-2"
            elevation="1"
            @click="isNewItem = true"
          >
            <v-icon color="white" large>mdi-plus</v-icon>
          </v-btn>
          <span>{{ $t("app.newItem") }}</span>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import AddDisplayItem from "./AddDisplayItem.vue";
import moment from "moment";
import Schedule from "./Schedule.vue";
import { IMAGE_BASE } from "../util/constants.js";

export default {
  name: "FridgeList",
  components: { AddDisplayItem, Schedule },
  data() {
    return {
      isImageViewer: false,
      headers: [
        {
          text: this.$t("store.scheduling"),
          sortable: false,
        },
        {
          text: this.$t("form.name"),
          sortable: false,
        },
        {
          text: this.$t("app.image"),
          sortable: false,
        },
        {
          text: this.$t("store.capacity"),
          sortable: false,
        },
        {
          text: this.$t("store.size"),
          sortable: false,
        },
        {
          text: this.$t("store.value"),
          sortable: false,
        },
        {
          text: this.$t("store.promotionalValue"),
          sortable: false,
        },
        {
          text: this.$t("app.actions"),
          sortable: false,
        },
      ],
      data: [],
      isSchedule: false,
      activeItem: null,
      activeItems: [],
      dialogs: [],
      dialog: false,
      isEdit: false,
      currentPage: 1,
      previewLink: "",
      isNewItem: false,
      local: {
        isPending: false,
      },
      filter: {
        keyword: "Keres",
        pending: true,
      },
    };
  },
  created() {
    eventBus.$on("QUIT_EDIT", () => {
      this.activeItem = null;
      this.isEdit = false;
    });
    eventBus.$on("CANCEL_DISPLAY_ITEM", () => {
      this.isNewItem = false;
      this.isEdit = false;
      this.isSchedule = false;
    });
    eventBus.$on("SAVE_DISPLAY_ITEM", () => {
      this.getList();
      this.isNewItem = false;
      this.isSchedule = false;
    });
    eventBus.$on("REFRESH", () => {
      this.getList();
      this.isNewItem = false;
      this.isSchedule = false;
    });
    eventBus.$on("SCHEDULE_NOW", () => {
      if (this.activeItems.length > 0) {
        this.isSchedule = true;
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: this.$t("message.unselected"),
        });
      }
    });
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    updateSelected() {
      this.activeItems = [];
      this.data.forEach((item) => {
        if (item.selected === true) {
          this.activeItems.push(item);
        }
      });
    },
    formatDate(d) {
      return moment.unix(d).format("YYYY.MM.DD");
    },
    showImage(item) {
      this.previewLink = this.getImageUrl(item);
      this.isImageViewer = true;
    },
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/fridge-display-types`,
          data: null,
        })
        .then((res) => {
          if (res.length > 0) {
            this.data = res;
          }
        });
    },
    getImageUrl(item) {
      return `${IMAGE_BASE}/storage/sample-images/${item.sampleImage.filename}`;
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    editItem(item) {
      this.isEdit = true;
      this.activeItem = item;
    },
    finalize() {
      this.$store
        .dispatch("removeItem", {
          end: `admin/fridge-display-types/${this.activeItem.id}`,
        })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    saveItem() {},
  },
};
</script>
