<template>
  <div class="FilePreview d-flex flex-wrap">
    <v-card class="mr-2 mb-2" v-for="(item, index) in urls" :key="index">
      <v-card-text class="rscope">
        <figure>
          <img width="120" height="120" :src="item.url" />
        </figure>
        <div class="overlay">
          <v-btn small fab @click="editItem(item)">
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <v-btn v-if="!nodelete" small fab @click="deleteItem(item)">
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isSingle" max-width="800">
      <v-card>
        <v-card-text>
          <v-img :src="single.url"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import { IMAGE_BASE } from "../util/constants.js";
export default {
  name: "FilePreview",
  props: ["items", "type", "nodelete"],
  data() {
    return {
      single: {
        url: null,
        name: null,
      },
      isSingle: false,
    };
  },
  computed: {
    urls() {
      let a = [];
      if (this.type === "files") {
        this.items.forEach((item) => {
          a.push({
            url: URL.createObjectURL(item),
            name: item.name,
          });
        });
      } else {
        this.items.forEach((item) => {
          let path = item.dirPath.substring(1);
          let name = item.filename;
          a.push({
            url: `${IMAGE_BASE}${path}${name}`,
            name: item.filename,
            id: item.id,
          });
        });
      }
      return a;
    },
  },
  methods: {
    editItem(item) {
      this.single = item;
      this.isSingle = true;
    },
    deleteItem(item) {
      if (this.type === "files") {
        console.log("remove?");
        eventBus.$emit("REMOVE_PREVIEW_ITEM", item);
      } else {
        console.log("delete?");
        eventBus.$emit("DELETE_PREVIEW_ITEM", item);
        this.items = this.items.filter((image) => image.filename !== item.name);
      }
    },
  },
};
</script>
<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}
.rscope:hover .overlay {
  display: flex;
}
</style>
