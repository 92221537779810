<template>
  <div class="list-panel">
    <v-container>
      <v-card class="card">
        <v-data-table hide-default-footer :headers="headers" :items="table">
          <template v-slot:item="row">
            <tr>
              <td class="text-xs-right">{{ row.item.storeCode }}</td>
              <!-- <td class="text-xs-right">{{ row.item.zipCode }}</td> -->
              <td class="text-xs-right">{{ row.item.storeAddress }}</td>
              <td class="text-xs-right">
                <v-btn icon @click="deleteItem(row.item)" elevation="1">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("app.noData") }}</p>
          </template>
        </v-data-table>
        <!-- // NEW ITEM -->
        <v-dialog v-model="isNewItem" max-width="600">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("app.newItem") }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-form v-model="isNewItemValid">
                <v-text-field
                  :label="$t('store.storeCode')"
                  outlined
                  v-model="newItem.storeCode"
                  required
                  :rules="defaultRules"
                ></v-text-field>
                <v-text-field
                  :label="$t('store.zipCode')"
                  outlined
                  v-model="newItem.zipCode"
                  required
                  type="number"
                  :rules="defaultRules"
                ></v-text-field>
                <v-text-field
                  :label="$t('store.storeAddress')"
                  outlined
                  v-model="newItem.storeAddress"
                  required
                  :rules="defaultRules"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="isNewItem = false">
                {{ $t("app.cancel") }}
              </v-btn>
              <v-btn
                :disabled="!isNewItemValid"
                @click="addNewItem()"
                elevation="1"
              >
                {{ $t("app.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--  -->
        <v-dialog v-model="dialog" max-width="600">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("app.deleteWarning") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">
                {{ $t("app.cancel") }}
              </v-btn>
              <v-btn @click="finalize()" color="red lighten-1">
                {{ $t("app.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--  -->
        <v-dialog v-model="isImport" max-width="600">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("app.import") }}
            </v-card-title>
            <v-card-text>
              <v-file-input
                v-model="importFile"
                label=".XLSX File"
                show-size
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="isImport = false">
                {{ $t("app.cancel") }}
              </v-btn>
              <v-btn @click="importFromFile()">
                {{ $t("app.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-pagination
          v-if="hasPagination"
          v-model="currentPage"
          :length="data.totalPages"
          total-visible="9"
          class="pagination"
        ></v-pagination>
        <v-card-text>
          <div class="f">
            <div class="f center" style="margin-right: 2rem">
              <v-btn fab color="black" elevation="1" @click="isNewItem = true">
                <v-icon color="white" large>mdi-plus</v-icon>
              </v-btn>
              <span>{{ $t("app.newItem") }}</span>
            </div>
            <div class="f center">
              <v-btn fab color="black" elevation="1" @click="isImport = true">
                <v-icon color="white" large>mdi-plus</v-icon>
              </v-btn>
              <span>{{ $t("app.import") }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import Pager from "../components/Pager.vue";
import { getMultipartHeader } from "../util/functions.js";
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";

export default {
  name: "settingsView",
  components: {},
  data() {
    return {
      clients: [],
      data: {},
      dialog: false,
      table: [],
      activeItem: null,
      isNewItem: false,
      isNewItemValid: false,
      newItem: {
        storeAddress: "",
        storeCode: "",
        zipCode: null,
      },
      importFile: null,
      isImport: false,
      headers: [
        {
          text: this.$t("store.storeCode"),
          value: "storeCode",
        },
        // {
        //   text: this.$t("store.zipCode"),
        //   value: "zipCode",
        // },
        {
          text: this.$t("store.storeAddress"),
          value: "storeAddress",
        },
        {
          text: this.$t("app.actions"),
        },
      ],
      currentPage: 1,
      defaultRules: [(v) => !!v || "Kötelező"],
    };
  },
  mounted() {
    this.getList();
    eventBus.$on("REFRESH", () => {
      this.getList();
    });
  },
  computed: {
    hasPagination() {
      return this.table.length > 0 && this.data.totalPages > 1;
    },
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/stores/page/${this.currentPage}`,
          data: null,
        })
        .then((res) => {
          this.table = res.data;
          this.data = res;
        });
    },
    handlePagination() {
      this.getList();
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    addNewItem() {
      this.$store
        .dispatch("addNewItem", { end: `admin/stores`, data: this.newItem })
        .then((res) => {
          this.isNewItem = false;
          this.newItem = {
            storeAddress: "",
            storeCode: "",
            zipCode: null,
          };
        });
    },
    finalize() {
      this.$store
        .dispatch("removeItem", `admin/stores/${this.activeItem.storeCode}`)
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    importFromFile() {
      let formdata = new FormData();
      console.log("import");

      this.$store
        .dispatch("importFromFile", {
          end: "admin/stores/xls",
          data: this.importFile,
        })
        .then((res) => {
          if (res !== false) {
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.success"),
            });
            this.isImport = false;
            this.importFile = null;
            location.reload();
          } else {
            messageBus.$emit("onNewMessage", {
              type: "error",
              label: this.$t("message.error"),
            });
            this.isImport = false;
            this.importFile = null;
          }
        });
    },
  },
  watch: {
    currentPage: function () {
      this.getList();
    },
  },
};
</script>
<style></style>
<style scoped>
.f {
  display: flex;
  align-items: center;
}
.f > span {
  margin-left: 1rem;
  font-weight: 500;
  font-size: 125%;
  color: #000;
}
.card {
  /*padding: 1rem;*/
  margin: 0.5rem;
  padding-bottom: 1rem;
}
.pagination {
  margin: 1rem 0;
}
</style>
