<template>
  <div class="Schedule">
    <v-card>
      <v-card-title>
        {{ $t("store.scheduling") }}
      </v-card-title>
      <v-card-text>
        <v-subheader>Kiválasztott elemek</v-subheader>
        <div class="items">
          <v-chip
            close
            close-icon="mdi-close-outline"
            outlined
            v-for="item in items"
            @click:close="removeItem(item)"
            :key="item.name"
            >{{ item.name }}</v-chip
          >
        </div>
        <v-subheader>Akció időtartama </v-subheader>
        <div class="f d-flex">
          <v-menu
            v-model="isFrom"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="fromDateFormatted"
                label="Akció kezdete"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mr-4"
                outlined
                append-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              @change="isFrom = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="isTo"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="toDateFormatted"
                label="Akció vége"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                :disabled="!dateFrom"
                append-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              @change="isTo = false"
              :min="dateFrom"
            ></v-date-picker>
          </v-menu>
        </div>
        <!--   <v-subheader
          >Pont Szorzó:<br /><b class="bigDigit"
            >&nbsp;{{ this.model.multiplier }}</b
          >
        </v-subheader> -->
        <v-select
          v-model="model.multiplier"
          :items="multiplierSelector"
          item-text="label"
          item-value="val"
          label="Szorzó"
          persistent-hint
          single-line
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isValid" x-large color="secondary" @click="submit()">
          {{ $t("form.send") }}
        </v-btn>
        <v-btn x-large @click="cancel()">
          {{ $t("form.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE } from "../util/constants.js";
export default {
  props: ["items"],
  data() {
    return {
      model: {
        fridgeDisplayTypeIds: [],
        multiplier: 0,
        promotionEndDate: 0,
        promotionStartDate: 0,
      },
      dates: [],
      isFrom: false,
      isTo: false,
      dateFrom: null,
      dateTo: null,
      multiplierSelector: [
        { label: "Kétszeres", val: 2 },
        { label: "Háromszoros", val: 3 },
      ],
    };
  },
  mounted() {
    this.items.forEach((item) => this.model.fridgeDisplayTypeIds.push(item.id));
  },
  methods: {
    removeItem(item) {
      this.items = this.items.filter((obj) => {
        return obj.name !== item.name;
      });
    },
    submit() {
      this.model.promotionStartDate = moment(this.dateFrom).unix();
      this.model.promotionEndDate = moment(this.dateTo).unix();
      var myHeaders = getLoggedInHeader(localStorage.getItem("token"));
      var raw = JSON.stringify(this.model);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${API_BASE}admin/fridge-display-types/promotion`, requestOptions)
        .then((response) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          eventBus.$emit("REFRESH");
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    cancel() {
      eventBus.$emit("CANCEL_DISPLAY_ITEM");
    },
  },
  computed: {
    fromDateFormatted() {
      return this.dateFrom ? moment(this.dateFrom).format("YYYY.MM.DD") : "";
    },
    toDateFormatted() {
      return this.dateTo ? moment(this.dateTo).format("YYYY.MM.DD") : "";
    },
    isValid() {
      return this.dateTo && this.dateFrom && this.model.multiplier >= 1;
    },
  },
};
</script>
<style scoped>
.Schedule {
  overflow-x: hidden;
}
.v-subheader {
  padding-left: 0;
}
</style>
